import Home from '@interco/icons/build-v4/orangeds/MD/home'
import { Link } from 'gatsby'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Img from 'gatsby-image'
import BlogArticles from 'src/components/BlogArticles'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import { InvestmentsCard } from 'src/components/InvestmentsCard'
import { FixedIncome } from 'src/components/InvestmentSimulators'
import ScrollTo from 'src/components/ScrollTo'
import FAQ from 'src/components/StructuredData/FAQ'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Layout from 'src/layouts/BaseLayout'

import { Modal } from 'src/components/Modal'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'
import headerJson from './header.json'
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import { Button, FAQSection, Section } from './style'

const { qrCodeAberturaDeContaRendaFixa, qrCodeContaKidsAberturaDeContasInvestimentos }: { qrCodeAberturaDeContaRendaFixa: { qrcode: string; deeplink: string }; qrCodeContaKidsAberturaDeContasInvestimentos: { qrcode: string; deeplink: string } } = qrcodeInvestimentsData

function RendaFixa () {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = React.useState(false)
  const domReady = useDomReady()

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setIsModalOpen(false)}
        qrBaixeApp={qrCodeAberturaDeContaRendaFixa.qrcode}
        asFilledLink={qrCodeAberturaDeContaRendaFixa.deeplink}
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_07',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const handleDatalayerEvent = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Comece a investir agora',
      section_name: 'Conheça nossos produtos',
    })
  }

  return (
    <Layout pageContext={pageContext}>
      {cardsModal}
      <Section>
        <div className='container pt-md-5 pb-5'>
          <div className='col-12 d-md-none'>
            <Img fluid={data.bannerMobile.fluid} alt='Tela do Super App da Inter Invest mostrando como investir em renda fixa.' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 px-0 p-md-3 justify-content-center align-items-center'>
            <div className='col-12 mb-3 pl-0'>
              <div className='bread'>
                <Link
                  to='/'
                  className='d-md-inline'
                ><Home height={24} width={24} color='#6A6E81' />
                </Link>
                <OrangeIcon className='d-md-inline mx-2' icon='arrow-right' color='#161616' size='SM' />
                <Link
                  to='/pra-voce/investimentos/'
                  className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline mr-2'
                >
                  {headerJson.breadcrumb[0]}
                </Link>
                <OrangeIcon icon='arrow-right' color='#161616' size='SM' />
                <p className='fs-12 fs-lg-14 lh-14 fw-700 text-grayscale--400 d-inline ml-2 mb-0'>{headerJson.breadcrumb[1]}</p>
              </div>
            </div>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 mb-4 text-grayscale--500 fw-600 font-sora'>
              Renda Fixa: invista
              <span className='d-md-block'> com segurança</span>
            </h1>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--400 mb-4'>
              Investir em Renda Fixa é a melhor opção para
              <span className='d-md-block'> quem quer ter uma rentabilidade previsível além </span>
              de segurança e liquidez flexível.
            </p>
            <ScrollTo
              to='#grafico'
              section='dobra_01'
              sectionName='Renda Fixa: invista com segurança'
              elementName='Compare agora!'
              styles='rounded-2 text-none mt-md-2'
            >
              <Button>
                Compare Agora!
              </Button>
            </ScrollTo>
          </div>
        </div>
      </Section>

      <section id='renda-fixa' className='pb-5 pt-md-5'>
        <div className='container'>
          <h2 className='fs-28 fs-md-42 text-md-center text-grayscale--500 mb-4 mt-2 font-sora'>Conheça nossos produtos</h2>
          <div className='d-flex justify-content-center'>
            <div className='col-12 col-md-8 col-lg-5 px-0'>
              <p id='grafico' className='fs-16 text-md-center text-grayscale--400'>Veja qual se encaixa melhor no seus planos e  comece já a investir.</p>
            </div>
          </div>
          <div className='shadow mt-4 mb-5 rounded-3 p-3'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-4'>
                <div className='box-content mt-0 mt-lg-5 mb-4'>
                  <p className='fw-700 mb-1 text-grayscale--500'>Considerações utilizadas nos exemplos:</p>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>Todos os valores demonstrados são brutos. Títulos isentos de IR, como LCI e Poupança, foram considerados com taxas equivalentes como se o investimento fosse tributável (com gross up).</li>
                    <li>A expectativa é de que a <a href='https://blog.inter.co/taxa-selic-hoje/'>taxa Selic</a> oscile durante o período, do contrário, a rentabilidade poderá ser afetada.</li>
                    <li>Considera-se que o Título do Tesouro Direto é o Tesouro SELIC (LFT) e sua rentabilidade é de 100% da taxa SELIC.</li>
                  </ul>
                </div>
                <div className='d-block d-lg-none'>
                  <a
                    href='https://inter-co.onelink.me/Qyu7/ifu4cn4r'
                    onClick={() => {
                      handleDatalayerEvent()
                    }}
                    className='btn btn--orange btn--lg rounded-3'
                  >Comece a investir agora
                  </a>
                </div>
                <div className='d-none d-lg-block'>
                  <div
                    onClick={() => {
                        setIsModalOpen(true)
                        handleDatalayerEvent()
                    }}
                    className='btn btn--orange btn--lg rounded-3'
                  >Comece a investir agora
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-8'>
                <FixedIncome />
              </div>
            </div>
          </div>
          <div id='produtos' className='col-12 px-0'>
            <div className='row'>
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'CDB'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/cdb/`,
                  })
                }}
                title='CDB'
                description='É garantido pelo FGC e rende mais do que a poupança. Quanto mais tempo deixar investido, maior será o rendimento e a alíquota do IR irá diminuir.'
                link='/pra-voce/investimentos/renda-fixa/cdb/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'LCI'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/lci-di/`,
                  })
                }}
                title='LCI'
                description='É um investimento garantido pelo FGC com lastro em operações de crédito imobiliário financiadas por instituições financeiras e com isenção de IR.'
                link='/pra-voce/investimentos/renda-fixa/lci-di/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'LCA'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/lca/`,
                  })
                }}
                title='LCA'
                description='Com prazo a partir de 90 dias e isenção de IR, é uma ótima alternativa de investimento para quem busca rentabilidade e segurança.'
                link='/pra-voce/investimentos/renda-fixa/lca/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'CRI'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/cri/`,
                  })
                }}
                title='CRI'
                description='Além de ser isenta de Imposto de Renda e ter diversas opções de prazo, ela oferece retornos pré-fixados, pós-fixados e híbridos.'
                link='/pra-voce/investimentos/renda-fixa/cri/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'CRA'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/cra/`,
                  })
                }}
                title='CRA'
                description='Pode ser indexado ao CDI, IGPM ou ao IPCA. É uma opção de investimento com rendimentos acima da poupança e sem desconto de IR.'
                link='/pra-voce/investimentos/renda-fixa/cra/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'Debêntures'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/debentures/`,
                  })
                }}
                title='Debêntures' description='Ao comprar um título de uma empresa, você receberá os rendimentos, que podem ser pós-fixados, pré-fixados ou híbridos.' link='/pra-voce/investimentos/renda-fixa/debentures/' textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'Poupança'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/poupanca/`,
                  })
                }}
                title='Poupança'
                description='Um investimento fácil e seguro para quem quer começar uma reserva financeira. Sem valor mínimo de aplicação e sem data de resgate.'
                link='/pra-voce/investimentos/poupanca/'
                textbutton='saiba mais'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    element_action: 'click button',
                    element_name: `${'Saiba mais'}${' - '}${'Tesouro Direto'}`,
                    section_name: 'Conheça nossos produtos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-fixa/tesouro-direto/`,
                  })
                }}
                title='Tesouro Direto'
                description='O Tesouro Direto é um dos investimentos mais seguros do país. Tem liquidez diária e você pode negociar os papeis diariamente se precisar.'
                link='/pra-voce/investimentos/renda-fixa/tesouro-direto/'
                textbutton='saiba mais'
              />
            </div>
          </div>
        </div>
      </section>
      <section id='outros-investimentos' className='pt-5 pb-5'>
        <div className='container'>
          <h2 className='fs-28 fs-md-42 mb-4 text-md-center mt-2 text-grayscale--500 font-sora'>Veja outros tipos de investimentos</h2>
          <div className='d-flex justify-content-center mt-5'>
            <div className='row shadow rounded-3 pt-4 pb-1 px-2'>
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    element_action: 'click button',
                    element_name: 'Fundos de investimento',
                    section_name: 'Veja outros tipos de investimentos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/fundos-de-investimento`,
                  })
                }}
                title='Fundos de investimento'
                description='Uma excelente estratégia para elevar o desempenho das suas aplicações. E aqui no Banco Inter, você pode receber parte do seu dinheiro de volta com o Casback de Fundos.'
                link='/pra-voce/investimentos/fundos-de-investimento/'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    element_action: 'click button',
                    element_name: 'Previdência Privada',
                    section_name: 'Veja outros tipos de investimentos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/previdencia-privada`,
                  })
                }}
                title='Previdência Privada'
                description='Ótima opção para quem procura uma alternativa ao INSS. Aqui no Banco Inter a contratação da previdência é 100% digital, tudo direto pelo nosso app.'
                link='/pra-voce/investimentos/previdencia-privada/'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    element_action: 'click button',
                    element_name: 'Renda Variável',
                    section_name: 'Veja outros tipos de investimentos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/renda-variavel`,
                  })
                }}
                title='Renda Variável'
                description='Invista em ações com um Home Broker totalmente gratuito e total liberdade para acompanhar seus investimentos.'
                link='/pra-voce/investimentos/renda-variavel/'
              />
              <InvestmentsCard
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    element_action: 'click button',
                    element_name: 'Ofertas Públicas',
                    section_name: 'Veja outros tipos de investimentos',
                    redirect_url: `${window.location.origin}/pra-voce/investimentos/ofertas-publicas`,
                  })
                }}
                title='Ofertas Públicas'
                description='Uma modalidade que oferece rentabilidades atrativas, seja em renda fixa ou variável. E aqui no Banco Inter você tem acesso as melhores opções de Ofertas Públicas.'
                link='/pra-voce/investimentos/ofertas-publicas/'
              />
            </div>
          </div>
        </div>
      </section>
      <section id='novidades-investimentos' className='mb-5 pt-lg-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-4'>
              <h2 className='fs-36 mb-0 fs-lg-42 text-left text-lg-center text-grayscale--500 font-sora'>
                Os melhores conteúdos sobre investimentos
              </h2>
              <p className='text-left mt-1 text-lg-center text-grayscale--400'>
                Esteja atualizado com as novidades do blog e invista com segurança aumentando os seus resultados.
              </p>
            </div>
            <BlogArticles
              type='investments'
              section='dobra_4'
              elementAction='click button'
              elementName='Saiba mais'
              sectionName='Confira algumas dicas para investir melhor'
            />
          </div>
        </div>
      </section>
      <FAQSection id='perguntas-frequentes' className='bg-gray pt-5 pb-5'>
        <div className='container'>
          <div className='content-main'>
            <h2 className='fs-28 fs-md-42 mb-4 text-md-center mt-2 text-grayscale--500 font-sora'>Perguntas frequentes</h2>
            <div className='d-flex justify-content-center'>
              <div className='col-12 col-md-8 col-lg-5 px-0'>
                <p className='fs-16 text-md-center text-grayscale--400'>
                  Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas perguntas
                  ou entre em contato com a gente.
                </p>
              </div>
            </div>
            <div className='row mt-3'>
              <FAQ data={pageContext.structuredData.faq} />
            </div>
          </div>
        </div>
      </FAQSection>
      <DisclaimerInterInvest
        sendDatalayerEvent={sendDatalayerEvent}
        dataLayer={dataLayer}
        section='dobra_06'
      />
    </Layout>
  )
}

export default RendaFixa
